import { ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'

const isEmptyObject = (data) => {
  return data.constructor === Object && Object.keys(data).length === 0
}

const useDebounceFnStoppable = (fn, ms) => {
  const stopping = ref(false)

  const debounce = useDebounceFn((...args) => {
    if (!stopping.value) return fn.apply(null, args)
  }, ms)

  return {
    debounced: (...args) => {
      stopping.value = false
      return debounce.call(null, ...args)
    },
    stop: () => (stopping.value = true)
  }
}

export { isEmptyObject, useDebounceFnStoppable }
