const setCourseStatus = (course) => {
  if (course.enrollment === null) {
    return 'not_started'
  } else if (course.enrollment.completed) {
    return 'completed'
  }
  return 'in_progress'
}

const setCompletedLessonsCount = (course) => {
  if (course.enrollment === null) {
    return 0
  } else if (course.enrollment.completed) {
    return course.lessons_count
  }
  return course.enrollment.advancements_count
}

const isCourseCertificateLocked = (chapters) => {
  const contentChapters = chapters.slice(0, -1)
  return contentChapters.some((chapter) => !chapter.completed)
}

export { setCourseStatus, setCompletedLessonsCount, isCourseCertificateLocked }
