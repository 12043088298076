import { useConfigStore, useUserStore } from '@/common/stores'

export const redirectFromManuallyExcludedCompanies = (to) => {
  const { configData } = useConfigStore()
  const userStore = useUserStore()

  const companyId = to.params?.companyId
  const excludedCompanyIds = configData.excludedCompanyIds

  if (
    companyId &&
    excludedCompanyIds.includes(companyId) &&
    !userStore.isAdminUser
  ) {
    return { name: 'Redirect' }
  }
  return true
}
