import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { api } from '@/services'
import * as Sentry from '@sentry/vue'

const useFeaturesStore = defineStore('features', () => {
  const features = ref([])
  const isLoading = ref(false)

  const fetchFeatures = async (companyId) => {
    if (isLoading.value) return
    if (features.value.length > 0) return
    isLoading.value = true

    try {
      const featuresResponse = await api.get(
        `/core-services/features?companyId=${companyId}`
      )

      features.value = featuresResponse.data.enabled
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      isLoading.value = false
    }
  }

  const isFeatureEnabled = (featureName) => {
    return features.value.includes(featureName)
  }

  const isAdoptionOfPermissionsServicesEnabled = computed(() => {
    return isFeatureEnabled('academy:adoption_of_permissions_service')
  })

  return {
    features,
    isAdoptionOfPermissionsServicesEnabled,
    fetchFeatures
  }
})

export default useFeaturesStore
