import { redirectNonMaintainers } from '@/router/middlewares'

const CompanyOverviewForMaintainer = () =>
  import(
    '@/pages/maintenance/components/CompanyOverviewForMaintainer/CompanyOverviewForMaintainer.vue'
  )
const ManageMembers = () =>
  import('@/pages/maintenance/components/ManageMembers/ManageMembers.vue')
const ManageMember = () =>
  import('@/pages/maintenance/components/ManageMember/ManageMember.vue')
const ManageCourses = () =>
  import('@/pages/maintenance/components/ManageCourses/ManageCourses.vue')
const ManageCourse = () =>
  import('@/pages/maintenance/components/ManageCourse/ManageCourse.vue')
const DownloadScormPackage = () =>
  import(
    '@/pages/maintenance/components/DownloadScormPackage/DownloadScormPackage.vue'
  )

export default [
  {
    name: 'Maintenance',
    path: 'maintenance',
    component: CompanyOverviewForMaintainer,
    beforeEnter: redirectNonMaintainers,
    redirect: { name: 'ManageMembersForMaintainer' },
    children: [
      {
        name: 'ManageMembersForMaintainer',
        path: 'members',
        component: ManageMembers
      },
      {
        name: 'ManageMemberForMaintainer',
        path: 'members/:userId',
        component: ManageMember
      },
      {
        name: 'ManageCoursesForMaintainer',
        path: 'courses',
        component: ManageCourses
      },
      {
        name: 'ManageCourseForMaintainer',
        path: 'courses/:companyCourseId',
        component: ManageCourse
      }
    ]
  },
  {
    name: 'DownloadCourseAsScormForMaintainer',
    path: 'maintenance/courses/:companyCourseId/download-scorm-package',
    beforeEnter: redirectNonMaintainers,
    component: DownloadScormPackage
  }
]
