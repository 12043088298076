import { api } from '@/services'

const replacePath = (path, origin, destination) => {
  return path.replace(origin, destination)
}

const bulkReplacePath = (routeObject, pathKeys, origin, destination) => {
  pathKeys.forEach((key) => {
    routeObject[key] = replacePath(routeObject[key], origin, destination)
  })

  return routeObject
}

export const replacePlatformIdWithUniversalId = async (to, _from, next) => {
  const platformId = !isNaN(to.params.companyId) ? to.params.companyId : null
  if (platformId) {
    const res = await api.get(`/core-services/companies/${to.params.companyId}`)
    const company = res.data?.data
    if (company?.companyId) {
      to.params.companyId = company.companyId

      bulkReplacePath(
        to,
        ['path', 'fullPath', 'href'],
        `/${platformId}`,
        `/${company.companyId}`
      )
    }
  }

  next()
}
