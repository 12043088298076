const Course = () => import('./components/Course.vue')
const Lessons = () => import('./components/Lessons.vue')
const SelectedLesson = () => import('./components/SelectedLesson')
export default {
  name: 'Course',
  path: 'courses/:courseId',
  component: Course,
  children: [
    {
      name: 'Lesson',
      path: 'lessons',
      component: Lessons,
      children: [
        {
          name: 'SelectedLesson',
          path: ':lessonId',
          component: SelectedLesson
        }
      ]
    }
  ]
}
