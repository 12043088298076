import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import { api } from '@/services'
import { useApiStatus, statusOptions } from '@/composables/apiStatus'
import { formatMembershipData } from '../utils/formatMembershipData'

const useMembershipsStore = defineStore('memberships', () => {
  const currentCachedCompany = ref()

  const { status, isIdle, isFetching, hasError, isSuccess } = useApiStatus()

  const members = ref([])

  function setMembershipsList(memberships) {
    members.value = memberships.map(formatMembershipData)
  }
  function updateCompanyMembershipsList(updatedCompanyMember) {
    const formatedUpdatedCompanyMember =
      formatMembershipData(updatedCompanyMember)
    members.value.forEach((membership, index) => {
      if (formatedUpdatedCompanyMember.id === membership.id) {
        members.value[index] = formatedUpdatedCompanyMember
      }
    })
  }
  const fetchMembershipList = async ({ companyId }) => {
    if (currentCachedCompany.value === companyId) return
    const companyUsersEndpoint = `/core-services/company-users/companies/${companyId}/users`
    members.value = []
    status.value = statusOptions.fetching

    try {
      const response = await api.get(companyUsersEndpoint)
      const users = response.data.data
      users.sort((a, b) => {
        return a.firstName.localeCompare(b.firstName)
      })

      setMembershipsList(users)
      status.value = statusOptions.success
      currentCachedCompany.value = companyId
    } catch (error) {
      status.value = statusOptions.error
    }
  }

  const membersCount = computed(() => members.value.length)

  return {
    members,
    membersCount,
    isIdle,
    hasError,
    isSuccess,
    isFetching,
    updateCompanyMembershipsList,
    fetchMembershipList
  }
})
export default useMembershipsStore
