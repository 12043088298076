import { createRouter, createWebHistory } from 'vue-router'
import {
  redirectToLogin,
  replacePlatformIdWithUniversalId,
  redirectFromManuallyExcludedCompanies,
  redirectNonAdmins
} from './middlewares'

const DefaultLayout = () => import('@/layouts/DefaultLayout.vue')

import { DashboardRouteConfig } from '@/pages/dashboard'
import { CourseRouteConfig } from '@/pages/course'
import { AdminRouteConfig } from '@/pages/admin'
import { MaintenanceRouteConfig } from '@/pages/maintenance'

const routes = [
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/layouts/Login.vue')
  },
  {
    path: '/:lang/:companyId',
    name: 'Default',
    component: DefaultLayout,
    children: [
      DashboardRouteConfig,
      CourseRouteConfig,
      ...MaintenanceRouteConfig
    ]
  },
  {
    path: '/:lang/administration',
    name: 'AdminDefault',
    beforeEnter: redirectNonAdmins,
    component: DefaultLayout,
    children: AdminRouteConfig
  },
  {
    name: 'Logout',
    path: '/logout',
    component: () => import('@/layouts/Logout.vue')
  },
  {
    name: 'LinkedInRedirect',
    path: '/linkedin-redirect',
    component: () => import('@/layouts/LinkedInRedirect.vue')
  },
  {
    name: 'Redirect',
    path: '/:catchAll(.*)*',
    component: () => import('@/layouts/Redirect.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(redirectToLogin)
router.beforeEach(replacePlatformIdWithUniversalId)
router.beforeEach(redirectFromManuallyExcludedCompanies)

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('error loading dynamically imported module')
  ) {
    window.location = to.fullPath
  }
})

export default router
