import { api } from '@/services'
import { setCompletedLessonsCount } from '@/common/utils'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const useCoursesStore = defineStore('courses', () => {
  const list = ref([])

  function setCourses(courses) {
    const coursesMap = courses.map((course) => {
      return {
        ...course,
        // set number of completed lessons based on corresponding enrollment
        lessons_completed: setCompletedLessonsCount(course)
      }
    })
    list.value = coursesMap
  }
  async function fetchCourses({ params }) {
    const response = await api.get(
      `/api/companies/${params.companyId}/courses`,
      {
        params: {
          subjects: params.subjects,
          language: params.language
        }
      }
    )
    setCourses(response.data.data)
  }

  return { list, setCourses, fetchCourses }
})
export default useCoursesStore
