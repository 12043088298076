const CompaniesOverview = () =>
  import('@/pages/admin/components/CompaniesOverview/CompaniesOverview.vue')
const CompanyOverviewForAdmin = () =>
  import(
    '@/pages/admin/components/CompanyOverviewForAdmin/CompanyOverviewForAdmin.vue'
  )
const ManageCourses = () =>
  import('@/pages/maintenance/components/ManageCourses/ManageCourses.vue')
const ManageMembers = () =>
  import('@/pages/maintenance/components/ManageMembers/ManageMembers.vue')
const ManageMember = () =>
  import('@/pages/maintenance/components/ManageMember/ManageMember.vue')
const ManageCourse = () =>
  import('@/pages/maintenance/components/ManageCourse/ManageCourse.vue')
const DownloadScormPackage = () =>
  import(
    '@/pages/maintenance/components/DownloadScormPackage/DownloadScormPackage.vue'
  )

export default [
  {
    name: 'CompaniesOverview',
    path: 'customers',
    component: CompaniesOverview
  },
  {
    name: 'DownloadScormPackageForAdmin',
    path: 'courses/:companyCourseId/download-scorm-package',
    component: DownloadScormPackage
  },
  {
    name: 'CompanyOverviewForAdmin',
    path: 'customers/:companyId',
    component: CompanyOverviewForAdmin,
    redirect: { name: 'ManageMembersForAdmin' },
    children: [
      {
        name: 'ManageMembersForAdmin',
        path: 'members',
        component: ManageMembers
      },
      {
        name: 'ManageMemberForAdmin',
        path: 'members/:userId',
        component: ManageMember
      },
      {
        name: 'ManageCoursesForAdmin',
        path: 'courses',
        component: ManageCourses
      },
      {
        name: 'ManageCourseForAdmin',
        path: 'courses/:companyCourseId',
        component: ManageCourse
      }
    ]
  }
]
