import { api } from '@/services'
import {
  setCourseStatus,
  setCompletedLessonsCount,
  isCourseCertificateLocked
} from '@/common/utils'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

const useCourseStore = defineStore('course', () => {
  const details = ref({})
  const activeChapter = ref({})

  const courseLessons = computed(() => {
    const courseLessons = details.value.chapters.reduce(
      (allLessons, currentChapter) => {
        return allLessons.concat(currentChapter.lessons)
      },
      []
    )
    return courseLessons
  })

  const currentLessonId = computed(() => {
    const pendingLesson =
      courseLessons.value.find((lesson) => lesson.completed === false) ||
      courseLessons.value[courseLessons.value.length - 1]
    return pendingLesson.id
  })

  const currentEnrollment = computed(() => {
    return details.value.enrollment
  })

  const isLessonInProgress = computed(() => (lessonId) => {
    const currentLesson = courseLessons.value.find(
      (lesson) => lesson.id === lessonId
    )

    return currentLesson ? !currentLesson.completed : false
  })

  function setCourseDetails(course) {
    // add custom attributes for each chapter in a course, to identify whether
    // it is completed (i.e., with all its lessons completed)
    // and/ or active (i.e., includes the currently selected lesson)

    const isActiveChapter = (chapter) => {
      return chapter.id === activeChapter.value.id
    }
    const isChapterCompleted = (chapter) => {
      const isCompleted = (lesson) => lesson.completed === true
      return chapter.lessons.every(isCompleted)
    }
    const enhancedChapters = course.chapters.map((chapter) => {
      return {
        ...chapter,
        active: isActiveChapter(chapter),
        completed: isChapterCompleted(chapter)
      }
    })
    const activeCourse = {
      ...course,
      chapters: enhancedChapters,
      // set status and number of completed lessons based on corresponding enrollment
      status: setCourseStatus(course),
      lessons_completed: setCompletedLessonsCount(course),
      locked: isCourseCertificateLocked(enhancedChapters)
    }
    details.value = activeCourse
  }

  function setActiveChapter({ chapterId, active = true }) {
    details.value.chapters.forEach((chapter) => {
      if (chapter.id === chapterId) {
        chapter.active = active
        activeChapter.value = chapter
      } else {
        chapter.active = false
      }
    })
  }

  async function fetchCourseDetails({ courseId, companyId }) {
    const course = await api.get(
      `/api/companies/${companyId}/courses/${courseId}`
    )
    setCourseDetails(course.data.data)
  }

  async function createAdvancement({ companyId, enrollmentId, lessonId }) {
    await api.post(
      `/api/companies/${companyId}/enrollments/${enrollmentId}/advancements`,
      { data: { lesson_id: lessonId } }
    )
  }

  async function createCourseEnrollment({ companyId, courseId }) {
    await api
      .post(`/api/companies/${companyId}/enrollments`, {
        data: { course_id: courseId }
      })
      .then((response) => {
        details.value.enrollment = response.data.data
      })
  }

  return {
    details,
    activeChapter,
    courseLessons,
    currentLessonId,
    currentEnrollment,
    isLessonInProgress,
    setActiveChapter,
    fetchCourseDetails,
    createAdvancement,
    createCourseEnrollment
  }
})
export default useCourseStore
