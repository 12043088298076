import { createApp } from 'vue'
import { createPinia } from 'pinia'
import sentinelDs from '@task-force/sentinel-ds-vue3'
import { i18n, initSentry } from '@/services'
import router from '@/router'
import '@task-force/sentinel-ds-vue3/dist/sentinel-ds-vue3.css'
import './assets/index.css'
import './assets/font/Averta.css'
import App from './App.vue'

const pinia = createPinia()
const app = createApp(App)

initSentry(app, router)

app.use(sentinelDs)
app.use(router)
app.use(pinia)
app.use(i18n)
app.mount('#app')

/**
 * Api Mocking
 * To activate mocking, assign `true` to  mockStatus.
 */
import { mockAuthApi, mockCompaniesApi } from './mock'

if (process.env.NODE_ENV === 'development') {
  const mockStatus = false
  mockAuthApi(mockStatus)
  mockCompaniesApi(mockStatus)
}
