import Keycloak from 'keycloak-js'
import { useConfigStore } from '@/common/stores'
import { storeToRefs } from 'pinia'

const keycloakConfig = {
  onLoad: 'login-required',
  pkceMethod: import.meta.env.VITE_KEYCLOAK_PKCE_METHOD
}

let keycloakInstance = null

const init = async () => {
  const configStore = useConfigStore()
  const { configData } = storeToRefs(configStore)
  const { fetchConfigData } = configStore
  await fetchConfigData()

  keycloakInstance = new Keycloak({
    url: `${configData.value.url}`,
    realm: configData.value.realm,
    clientId: configData.value.clientId
  })
}

const checkSSO = async () => {
  if (!keycloakInstance) await init()

  await keycloakInstance.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri:
      window.location.origin + '/silent-check-sso.html',
    token: localStorage.getItem('access-token'),
    refreshToken: localStorage.getItem('refresh-token'),
    messageReceiveTimeout: 60000 // 60 seconds
  })
}

const clearGainsightCookies = () => {
  if (document.cookie.includes('apt.uid')) {
    document.cookie = 'apt.uid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }
  if (document.cookie.includes('apt.sid')) {
    document.cookie = 'apt.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  if (window.aptrinsic) {
    window.aptrinsic('reset')
  }
}

const login = async (redirectTo, onSuccess) => {
  await init()
  clearGainsightCookies()

  const redirectUri = redirectTo
    ? `${window.location.origin}/login?redirect_to=${redirectTo}`
    : `${window.location.origin}/login`

  const isAuthenticated = await keycloakInstance.init({
    onLoad: keycloakConfig.onLoad,
    pkceMethod: keycloakConfig.pkceMethod,
    enableLogging: true,
    checkLoginIframe: false,
    redirectUri: redirectUri
  })

  if (isAuthenticated) {
    localStorage.setItem('access-token', keycloakInstance.token)
    localStorage.setItem('refresh-token', keycloakInstance.refreshToken)

    onSuccess(redirectTo)
  } else {
    window.location.reload()
  }
}

const refreshToken = async () => {
  try {
    const refreshed = await keycloakInstance.updateToken(30)

    if (refreshed) {
      localStorage.setItem('access-token', keycloakInstance.token)
      localStorage.setItem('refresh-token', keycloakInstance.refreshToken)
    }

    return keycloakInstance.token
  } catch (error) {
    return null
  }
}

const logout = async () => {
  clearGainsightCookies()
  const logoutOptions = { redirectUri: window.location.origin + '/login' }

  localStorage.removeItem('access-token')
  localStorage.removeItem('refresh-token')
  localStorage.removeItem('linkedinMiscData')

  await keycloakInstance.logout(logoutOptions)
}

export { checkSSO, login, refreshToken, logout }
