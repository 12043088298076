import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { format } from 'date-fns'
import * as Sentry from '@sentry/vue'

import { api } from '@/services'
import { useCompanyCoursesStore } from '@/pages/maintenance'
import { useApiStatus, statusOptions } from '@/composables/apiStatus'

const useCompanyCourseStore = defineStore('companyCourse', () => {
  const cachedCourseMetadata = ref({})
  const cachedEnrollmentsMetadata = ref({})
  const details = ref({})
  const enrollments = ref([])

  const { status: courseStatus, isFetching: courseIsFetching } = useApiStatus()
  const { status: enrollmentsStatus, isFetching: enrollmentsIsFetching } =
    useApiStatus()

  const setManagedCourseDataWithFormattedDeadline = ({
    deadline,
    ...otherCourseData
  }) => {
    let formattedDeadline = ''
    if (deadline) formattedDeadline = format(new Date(deadline), 'dd.MM.yyyy')
    details.value = { deadline: formattedDeadline, ...otherCourseData }
  }

  const setEnrollmentList = (enrollmentsData) => {
    const formatEnrollmentData = (enrollment) => {
      const { id, course, user, created_at, completed, advancements_count } =
        enrollment

      const lessonsCount = course.lessons_count

      const progressPercentage =
        advancements_count == lessonsCount
          ? 100
          : Math.ceil((advancements_count * 100) / lessonsCount)

      return {
        id,
        created_at: format(new Date(created_at), 'dd.MM.yyy'),
        completed,
        full_name: user.full_name,
        name: user.full_name,
        member_progress_percentage: progressPercentage,
        advancements_count,
        course_lessons_count: lessonsCount
      }
    }
    return enrollmentsData.map(formatEnrollmentData)
  }
  const fetchManagedCourseDetails = async ({ companyId, companyCourseId }) => {
    if (
      cachedCourseMetadata.value.companyId === companyId &&
      cachedCourseMetadata.value.companyCourseId === companyCourseId
    )
      return
    if (courseIsFetching.value) return
    courseStatus.value = statusOptions.fetching
    try {
      const managedCourse = await api.get(
        `/api/maintenance/companies/${companyId}/courses/${companyCourseId}`
      )
      setManagedCourseDataWithFormattedDeadline(managedCourse.data.data)
      courseStatus.value = statusOptions.success
      cachedCourseMetadata.value = { companyId, companyCourseId }
    } catch (error) {
      courseStatus.value = statusOptions.error
      console.error(error)
      Sentry.captureException(error)
    }
  }

  const fetchEnrollments = async ({ companyId, companyCourseId }) => {
    if (
      cachedEnrollmentsMetadata.value.companyId === companyId &&
      cachedEnrollmentsMetadata.value.companyCourseId === companyCourseId
    )
      return
    if (enrollmentsIsFetching.value) return
    enrollmentsStatus.value = statusOptions.fetching
    const chunks = []
    const fetchedEnrollments = []
    try {
      const enrollmentsResponse = await api.get(
        `/api/maintenance/companies/${companyId}/enrollments?company_course_id=${companyCourseId}`
      )

      fetchedEnrollments.push(...enrollmentsResponse.data.data)
      const uniqueUserIds = Array.from(
        new Set(fetchedEnrollments.map((enrollment) => enrollment.user_id))
      )

      const chunkSize = 100
      for (let i = 0; i < uniqueUserIds.length; i += chunkSize) {
        chunks.push(uniqueUserIds.slice(i, i + chunkSize))
      }
      const userPromisesList = []
      for (let chunk of chunks) {
        const userIds = chunk.join(',')
        const usersEndpoint = `/core-services/company-users/companies/${companyId}/users?userIds=${userIds}`
        const usersResponse = api.get(usersEndpoint)
        userPromisesList.push(usersResponse)
      }
      const chunkedUsers = await Promise.all(userPromisesList)
      const users = chunkedUsers.reduce((acc, chunk) => {
        return acc.concat(chunk.data.data)
      }, [])

      fetchedEnrollments.forEach((enrollment, index) => {
        const user = users.find((user) => user.id === enrollment.user_id)
        if (user) {
          const newUserData = {
            id: user.id,
            full_name: user.firstName + ' ' + user.lastName,
            email: user.emailAddress
          }
          fetchedEnrollments[index].user = newUserData
        } else {
          fetchedEnrollments[index].user = {}
        }
      })

      fetchedEnrollments.sort((a, b) =>
        a?.user?.full_name?.localeCompare(b?.user?.full_name)
      )

      enrollments.value = setEnrollmentList(fetchedEnrollments)
      enrollmentsStatus.value = statusOptions.success
      cachedEnrollmentsMetadata.value = { companyId, companyCourseId }
    } catch (error) {
      enrollmentsStatus.value = statusOptions.error
      console.error(error)
      Sentry.captureException(error)
    }
  }

  const updateCourseStatus = async ({ companyId, recordId, newStatus }) => {
    try {
      const response = await api.patch(
        `/api/maintenance/companies/${companyId}/courses/${recordId}`,
        { data: { status: newStatus } }
      )
      const updatedCompanyCourse = response.data.data
      const companyCoursesStore = useCompanyCoursesStore()
      companyCoursesStore.updateCompanyCoursesList(updatedCompanyCourse)
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
  }

  const updateCourseMandatory = async ({
    companyId,
    recordId,
    isMandatory
  }) => {
    try {
      const response = await api.patch(
        `/api/maintenance/companies/${companyId}/courses/${recordId}`,
        { data: { mandatory: isMandatory } }
      )

      const updatedCompanyCourse = response.data.data
      const companyCoursesStore = useCompanyCoursesStore()

      companyCoursesStore.updateCompanyCoursesList(updatedCompanyCourse)
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
  }

  const updateCourseDeadline = async (
    companyId,
    courseId,
    selectedDeadline
  ) => {
    const response = await api.patch(
      `/api/maintenance/companies/${companyId}/courses/${courseId}`,
      { data: { deadline: selectedDeadline || null } }
    )

    setManagedCourseDataWithFormattedDeadline(response.data.data)
  }

  const enrollmentsCount = computed(() => enrollments.value.length)

  return {
    courseIsFetching,
    enrollmentsIsFetching,
    details,
    enrollments,
    enrollmentsCount,
    fetchEnrollments,
    fetchManagedCourseDetails,
    updateCourseStatus,
    updateCourseMandatory,
    updateCourseDeadline
  }
})
export default useCompanyCourseStore
