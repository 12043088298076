import { useUserStore } from '@/common/stores'

export const redirectNonAdmins = async (to, _from, next) => {
  const userStore = useUserStore()
  await userStore.decodeUserJwt()

  if (!userStore.isAdminUser) {
    next('/')
    return
  }

  next()
}
