import { createServer } from 'miragejs'

export default function mockServer(
  { mockConfig = { api: '/api', type: 'get' } } = {},
  { mockData = { data: [] } } = {}
) {
  createServer({
    seeds(server) {
      server.db.loadData({ mockData })
    },

    routes() {
      // to similute api call latency
      this.timing = 500

      // fetch data from a mock endpoint
      this[mockConfig.type](mockConfig.api, (schema) => {
        const mockData = schema.db.mockData
        return mockData[mockData.length - 1]
      })

      // It allows all other API requests to hit the real server
      this.passthrough()
      this.passthrough('https://stg-auth.dataguard.de/**')
    }
  })

  /**
   * deleteing the `onloadend` property from
   * XMLHttpRequest function's request body.
   * */
  const NativeXMLHttpRequest = window.XMLHttpRequest
  window.XMLHttpRequest = function XMLHttpRequest() {
    const request = new NativeXMLHttpRequest()
    delete request.onloadend
    return request
  }
}
