import { api } from '@/services'

import { defineStore } from 'pinia'
import { ref } from 'vue'

const endpoint = `/core-services/companies`

const useAdminStore = defineStore('admin', () => {
  const customerList = ref([])
  const customerListMetaData = ref({})
  const customerSearchResults = ref([])
  const customerSearchResultsMetaData = ref({})
  const customerDetails = ref({})
  const loading = ref(false)

  const fetchCustomerDetails = async (companyId) => {
    const response = await api.get(`${endpoint}?companyIds=${companyId}`)
    const company = response?.data?.data[0] ?? {}
    customerDetails.value = {
      id: company.companyId,
      jurisdiction_alpha_code:
        company.operatingJurisdictions && company.operatingJurisdictions[0],
      name: company.name,
      membership_active: true
    }
  }

  const clearCustomerDetails = () => {
    customerDetails.value = {}
  }

  return {
    customerDetails,
    customerList,
    customerListMetaData,
    customerSearchResults,
    customerSearchResultsMetaData,
    fetchCustomerDetails,
    clearCustomerDetails,
    loading
  }
})
export default useAdminStore
