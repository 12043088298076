import axios from 'axios'
import { refreshToken, login } from './auth'
import { getLocale } from './i18n'

const instance = axios.create({
  baseURL: '/'
})

instance.interceptors.request.use(
  async (request) => {
    if (localStorage.getItem('refresh-token')) {
      const accessToken = await refreshToken()

      request.headers['Authorization'] = `Bearer ${accessToken}`
      request.headers['Accept-Language'] = getLocale()
    }

    return request
  },
  (error) => {
    throw error
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (typeof error.response !== 'undefined') {
      const errorIsFaradayUnauthorized =
        error.response?.data?.errors?.length &&
        error.response.data.errors[0]?.title === 'Faraday::UnauthorizedError'

      const errorHasStatus401 = error?.response?.status === 401

      const needsToRefreshToken =
        errorIsFaradayUnauthorized || errorHasStatus401

      if (needsToRefreshToken) {
        let redirect = window.location.pathname
        if (window.location.search) {
          redirect += window.location.search
        }
        await login(redirect, () => {
          window.location.replaceState(redirect)
        })
        const token = localStorage.getItem('access-token')

        error.config.headers['Authorization'] = `Bearer ${token}`
        return instance.request(error.config)
      }
    }

    throw error
  }
)

export default instance
