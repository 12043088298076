import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import axios from 'axios'
import { isEmptyObject } from '../utils'

const useConfigStore = defineStore('config', () => {
  const configData = ref({})

  const envUrlPatterns = {
    production: 'auth.dataguard',
    demo: 'auth.demo.dataguard',
    staging: 'stg-auth.dataguard',
    development: 'stg-auth.dataguard'
  }

  const getEnvironment = computed(() => {
    if (isEmptyObject(configData)) return null
    const { hostname } = new URL(configData.value.url)
    const environment = Object.keys(envUrlPatterns).find((env) =>
      hostname.startsWith(envUrlPatterns[env])
    )
    return environment || null
  })

  const isEnvironment = (env) => computed(() => getEnvironment.value === env)

  const fetchConfigData = async () => {
    const response = await axios.get('/config/env-data')
    configData.value = response.data
  }

  return {
    fetchConfigData,
    configData,
    isProduction: isEnvironment('production'),
    isDemo: isEnvironment('demo'),
    isDevelopment: isEnvironment('development'),
    isStaging: isEnvironment('staging')
  }
})

export default useConfigStore
