import * as Sentry from '@sentry/browser'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

const escapeUnicode = (string) => {
  return string.replace(/(.)/g, (_match, character) => {
    return '%' + ('00' + character.charCodeAt(0).toString(16)).slice(-2)
  })
}
const hasMatchingRole = (allRoles, roleKeywords) => {
  return allRoles.some((role) =>
    roleKeywords.some((keyword) => role.includes(keyword))
  )
}

const isEmptyObject = (data) => {
  return data.constructor === Object && Object.keys(data).length === 0
}

const useUserStore = defineStore('user', () => {
  const userData = ref({})

  const userId = computed(() => userData.value.sub)

  const isAdminUser = computed(() => {
    if (isEmptyObject(userData.value)) return false
    const roles = userData.value.roles[''] || []

    return hasMatchingRole(roles, [
      'csm',
      'senior_csm',
      'dpo',
      'senior_dpo',
      'admin'
    ])
  })

  const isMaintainerUser = (companyPlatformId) => {
    if (isEmptyObject(userData.value)) return false
    const companyRoles = userData.value.roles[companyPlatformId] || []
    return hasMatchingRole(companyRoles, ['academy_maintainer'])
  }

  const decodeUserJwt = async () => {
    if (userData.value.id) return
    const base64URL = localStorage.getItem('access-token').split('.')[1]
    const base64 = base64URL.replace(/-/g, '+').replace(/_/g, '/')
    const encodedPayload = escapeUnicode(window.atob(base64))
    const decodedPayload = decodeURIComponent(encodedPayload)

    const userInfo = JSON.parse(decodedPayload)
    Sentry.setUser({ id: userInfo.sub })
    userData.value = userInfo
  }
  return { userData, decodeUserJwt, isAdminUser, isMaintainerUser, userId }
})

export default useUserStore
