import { createI18n } from 'vue-i18n'
import messages from '@intlify/vite-plugin-vue-i18n/messages'

const FALLBACK_LOCALE = 'en'
const AVAILABLE_LOCALES = ['de', 'en']

export const getLocale = () => {
  const firstPartOfPath = window.location.pathname.split('/').slice(1, 2)[0]
  const browserLanguage = navigator?.language.substring(0, 2)

  const localeFromPath = AVAILABLE_LOCALES.includes(firstPartOfPath)
    ? firstPartOfPath
    : null
  const localeFromLocalStorage = localStorage.getItem('locale')
  const localeFromBrowser = AVAILABLE_LOCALES.includes(browserLanguage)
    ? browserLanguage
    : null

  const locale =
    localeFromPath ||
    localeFromLocalStorage ||
    localeFromBrowser ||
    FALLBACK_LOCALE

  return locale
}

export const setLocale = (locale) => {
  locale = AVAILABLE_LOCALES.includes(locale) ? locale : FALLBACK_LOCALE

  localStorage.setItem('locale', locale)

  return locale
}

const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  fallbackLocale: FALLBACK_LOCALE,
  globalInjection: true,
  messages
})

export default i18n
