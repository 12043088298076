import { ref, computed } from 'vue'

export const statusOptions = {
  idle: 'idle',
  fetching: 'fetching',
  error: 'error',
  success: 'success'
}

export const useApiStatus = () => {
  const status = ref('idle')

  const isIdle = computed(() => status.value === statusOptions.idle)
  const isFetching = computed(() => status.value === statusOptions.fetching)
  const hasError = computed(() => status.value === statusOptions.error)
  const isSuccess = computed(() => status.value === statusOptions.success)

  return { status, isIdle, isFetching, hasError, isSuccess }
}
