import mockServer from '../mockServer'

// for user email : "ripoca2965@doerma.com"
const mockData = {
  data: [
    {
      id: 1019,
      name: 'DataGuard',
      package: 'Privacy & CISO',
      registration_link:
        'https://pic-crm.stg.dataguard.de/auth/registration/1Jw6d73ziW8miR8Gr1C9dPyv',
      jurisdiction_id: 1221,
      jurisdiction_alpha_code: 'de',
      role: 'maintainer'
    }
  ]
}

const mockConfig = {
  api: '/api/companies',
  type: 'get'
}

const mockCompaniesApi = (active = false) => {
  if (active) mockServer({ mockConfig }, { mockData })
}

export default mockCompaniesApi
