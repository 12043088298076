import { auth } from '@/services'

export const redirectToLogin = async (to, _from) => {
  if (to.name !== 'Login') {
    if (localStorage.getItem('access-token')) {
      await auth.checkSSO()
    } else {
      return { name: 'Login' }
    }
  }
}
