import { ref } from 'vue'
import { defineStore } from 'pinia'
import { format } from 'date-fns'

import { api } from '@/services'
import { useApiStatus, statusOptions } from '@/composables/apiStatus'

const useMembershipStore = defineStore('membership', () => {
  const currentCachedData = ref({ userId: null, companyId: null })
  const enrollments = ref([])

  const { status, isIdle, isFetching, hasError, isSuccess } = useApiStatus()

  const setEnrollmentList = (enrollmentsData) => {
    const formatEnrollmentData = (enrollment) => {
      const { id, course, created_at, completed, advancements_count } =
        enrollment

      const lessonsCount = course.lessons_count

      const progressPercentage =
        advancements_count == lessonsCount
          ? 100
          : Math.ceil((advancements_count * 100) / lessonsCount)

      return {
        id,
        created_at: format(new Date(created_at), 'dd.MM.yyy'),
        completed,
        course_title: course.title,
        member_progress_percentage: progressPercentage,
        advancements_count,
        course_lessons_count: lessonsCount
      }
    }

    enrollments.value = enrollmentsData.map(formatEnrollmentData)
  }

  const fetchMembershipEnrollments = async ({ companyId, userId }) => {
    if (
      userId === currentCachedData.value.userId &&
      companyId === currentCachedData.value.companyId
    )
      return

    if (isFetching.value) return

    status.value = statusOptions.fetching
    try {
      const response = await api.get(
        `/api/maintenance/companies/${companyId}/enrollments?user_id=${userId}&per_page=50`
      )

      setEnrollmentList(response.data.data)
      currentCachedData.value = { userId, companyId }
      status.value = statusOptions.success
    } catch (error) {
      status.value = statusOptions.error
    }
  }

  return {
    isFetching,
    hasError,
    isSuccess,
    isIdle,
    setEnrollmentList,
    fetchMembershipEnrollments,
    enrollments
  }
})
export default useMembershipStore
