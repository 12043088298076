import {
  useUserStore,
  useCompaniesStore,
  useUserPermissionStore,
  useFeaturesStore
} from '@/common/stores'

export const redirectNonMaintainers = async (to, _from, next) => {
  const companiesStore = useCompaniesStore()
  const userPermissionStore = useUserPermissionStore()
  const featuresStore = useFeaturesStore()

  const userStore = useUserStore()
  await userStore.decodeUserJwt()

  if (userStore.isAdminUser) {
    next()
    return
  }

  const currentCompanyId = to.params.companyId
  await companiesStore.fetchCompanyList(userStore.userId)

  const currentPlatformId =
    companiesStore.getCompanyPlatformIdById(currentCompanyId)

  const userRolesForCurrentCompany = userStore.userData.roles[currentPlatformId]

  const isCourseManager = featuresStore.isAdoptionOfPermissionsServicesEnabled
    ? userPermissionStore.isCourseManager(currentCompanyId)
    : userRolesForCurrentCompany.includes('academy_maintainer')

  if (!isCourseManager && !userStore.isAdminUser) {
    next({ name: 'Dashboard', params: to.params })
    return
  }

  next()
}
