<template>
  <router-view />
  <dg-notification v-bind="notification" @close="notification.show = false">
    <template #title> {{ notification.title }}</template>
    <template #description>
      {{ notification.description }}
    </template>
  </dg-notification>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import useNotification from '@/composables/notification'

const { t, locale } = useI18n()
document.title = t('titles.default')
document.documentElement.setAttribute('lang', locale.value)

const { notification } = useNotification()
</script>
