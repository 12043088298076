import { ref } from 'vue'
import { defineStore } from 'pinia'

import { api } from '@/services'
import { useApiStatus, statusOptions } from '@/composables/apiStatus'

const useMemberStore = defineStore('member', () => {
  const cachedMember = ref({})
  const member = ref({})
  const { status, isFetching } = useApiStatus()

  const fetchMember = async (userId, companyId) => {
    if (cachedMember.value.id === userId) return
    if (isFetching.value) return

    status.value = statusOptions.fetching
    try {
      const response = await api.get(
        `/core-services/company-users/companies/${companyId}/users/${userId}`
      )
      if (!response.data?.data?.id) {
        throw new Error('User not found')
      }
      member.value = response.data.data
      cachedMember.value = response.data.data
      status.value = statusOptions.success
    } catch (error) {
      status.value = statusOptions.error
    }
  }

  return {
    member,
    fetchMember
  }
})

export default useMemberStore
