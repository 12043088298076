import mockServer from '../mockServer'

const mockData = {
  url: 'https://stg-auth.dataguard.de',
  realm: 'dataguard',
  clientId: 'local-academy'
}

const mockConfig = {
  api: '/config/auth',
  type: 'get'
}

const mockAuthApi = (active = false) => {
  if (active) mockServer({ mockConfig }, { mockData })
}

export default mockAuthApi
