import { ref, computed, watch, onMounted } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from '@/common/stores'
import { api } from '@/services'
import * as Sentry from '@sentry/vue'

const usePermissionsStore = defineStore('permissions', () => {
  const userPermissions = ref({})
  const fetchingList = ref(false)

  const fetchPermissions = async () => {
    const userStore = useUserStore()

    if (
      userStore.isAdminUser ||
      Object.keys(userPermissions.value).length > 0 ||
      fetchingList.value
    ) {
      return
    }

    fetchingList.value = true
    try {
      const permissionsResponse = await api.get(`/core-services/permissions/me`)
      userPermissions.value = permissionsResponse.data
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      fetchingList.value = false
    }
  }

  const isCourseManager = (companyId) => {
    return userPermissions.value[companyId]?.includes(
      'academy:company_course:manage'
    )
  }

  const isEmployeeManager = (companyId) => {
    return userPermissions.value[companyId]?.includes(
      'academy:employees:manage'
    )
  }

  const canTransferCourseProgress = (companyId) => {
    return userPermissions.value[companyId]?.includes(
      'academy:company_course:progress_transfer'
    )
  }

  const isAcademyUser = (companyId) => {
    return userPermissions.value[companyId]?.includes(
      'academy:user:course_progress'
    )
  }

  return {
    userPermissions,
    fetchPermissions,
    isCourseManager,
    isEmployeeManager,
    canTransferCourseProgress,
    isAcademyUser
  }
})

export default usePermissionsStore
