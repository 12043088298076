import { reactive } from 'vue'

const notification = reactive({})

export default function useNotification() {
  const notify = (type) => {
    return (title, description) => {
      notification.show = true
      notification.type = type
      notification.title = title
      notification.description = description

      setTimeout(() => {
        notification.show = false
      }, 5000)
    }
  }
  const showInfo = notify('info')
  const showSuccess = notify('success')
  const showWarning = notify('warning')
  const showError = notify('error')

  return { notification, showInfo, showSuccess, showWarning, showError }
}
